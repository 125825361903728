<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
      <img
        src="/Logo-White.png"
        alt="dashboard"
        class="brand-image"
      />
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2" v-slimscroll="options">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          role="menu"
          data-accordion="false"
          style="padding-bottom: 15px;"
        >
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item">
            <router-link to="/dashboard" class="nav-link" exact active-class="active">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("DashboardModule.title") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/usersTypes" class="nav-link" exact active-class="active" v-if="$can('portal.users_types.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("UsersTypesModule.title") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/users" class="nav-link" exact active-class="active" v-if="$can('portal.users.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("UsersModule.title") }}
              </p>
            </router-link>
          </li>
           <li class="nav-item">
            <router-link to="/branches" class="nav-link" exact active-class="active" v-if="$can('portal.branches.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("BranchesModule.title") }}
              </p>
            </router-link>
          </li>
              <li class="nav-item">
            <router-link to="/places" class="nav-link" exact active-class="active" v-if="$can('portal.places.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("PlacesModule.title") }}
              </p>
            </router-link>
          </li>
               <li class="nav-item">
            <router-link to="/seats" class="nav-link" exact active-class="active" v-if="$can('portal.seats.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("SeatsModule.title") }}
              </p>
            </router-link>
          </li>
                <li class="nav-item">
            <router-link to="/total_templates" class="nav-link" exact active-class="active" v-if="$can('portal.total_templates.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("TotalTemplatesModule.title") }}
              </p>
            </router-link>
          </li>
              <li class="nav-item">
            <router-link to="/bills_types" class="nav-link" exact active-class="active" v-if="$can('portal.bills_types.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("BillTypesModule.title") }}
              </p>
            </router-link>
          </li>
           <li class="nav-item">
            <router-link to="/item_categories" class="nav-link" exact active-class="active" v-if="$can('portal.items_categories.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("ItemCategoriesModule.title") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/items" class="nav-link" exact active-class="active" v-if="$can('portal.items.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("ItemsModule.title") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/clients" class="nav-link" exact active-class="active" v-if="$can('portal.clients.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("ClientsModule.title") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/invoices" class="nav-link" exact active-class="active" v-if="$can('portal.invoices.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("InvoicesModule.title") }}
              </p>
            </router-link>
          </li>
           <li class="nav-item">
            <router-link to="/vat_report" class="nav-link" exact active-class="active" v-if="$can('portal.invoices.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("VatReportModule.title") }}
              </p>
            </router-link>
          </li>
           <li class="nav-item">
            <router-link to="/permissions" class="nav-link" exact active-class="active" v-if="$can('portal.permissions.show')">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("PermissionsModule.title") }}
              </p>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      c_tree: "",
      options: {
        height: "100%",
        size: 7,
        color: "#fff"
      }
    };
  },
  methods: {
    CTree(i) {
      if (i == this.c_tree) {
        this.c_tree = "";
      } else {
        this.c_tree = i;
      }
    }
  }
};
</script>
