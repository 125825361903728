import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import UsersTypes from "../views/UsersTypes.vue";
import Branches from "../views/Branches.vue";
import Places from "../views/Places.vue";
import Seats from "../views/Seats.vue";
import TotalTemplates from "../views/TotalTemplates.vue";
import BillTypes from "../views/BillTypes.vue";
import ItemCategories from "../views/ItemCategories.vue";
import Items from "../views/Items.vue";
import Clients from "../views/Clients.vue";
import Invoices from "../views/Invoices.vue";
import Users from "../views/Users.vue";
import VatReport from "../views/VatReport.vue";
import Permissions from "../views/Permissions.vue";


import Login from "../views/Login.vue";

import auth from "../middleware/auth.js";
//import can from "../middleware/can.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "Dashboard" },
    meta: { title: "home" }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Home,
    meta: { middleware: [auth], title: "DashboardModule" }
  },
  {
    path: "/branches",
    name: "Branches",
    component: Branches,
    meta: { middleware: [auth], title: "BranchesModule" }
  },
  {
    path: "/places",
    name: "Places",
    component: Places,
    meta: { middleware: [auth], title: "PlacesModule" }
  },
  {
    path: "/seats",
    name: "Seats",
    component: Seats,
    meta: { middleware: [auth], title: "SeatsModule" }
  },
  {
    path: "/total_templates",
    name: "TotalTemplates",
    component: TotalTemplates,
    meta: { middleware: [auth], title: "TotalTemplatesModule" }
  },
  {
    path: "/bills_types",
    name: "BillTypes",
    component: BillTypes,
    meta: { middleware: [auth], title: "BillTypesModule" }
  },
  {
    path: "/item_categories",
    name: "ItemCategories",
    component: ItemCategories,
    meta: { middleware: [auth], title: "ItemCategoriesModule" }
  },
  {
    path: "/items",
    name: "Items",
    component: Items,
    meta: { middleware: [auth], title: "ItemsModule" }
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    meta: { middleware: [auth], title: "ClientsModule" }
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: Invoices,
    meta: { middleware: [auth], title: "InvoicesModule" }
  },
  {
    path: "/usersTypes",
    name: "UsersTypes",
    component: UsersTypes,
    meta: { middleware: [auth], title: "UsersTypesModule",
    //permission: "admin.permissions.show"
   }
  },
   {
    path: "/users",
    name: "Users",
    component: Users,
    meta: { middleware: [auth], title: "UsersModule",
    //permission: "admin.permissions.show"
   }
  },
  {
    path: "/vat_report",
    name: "VatReport",
    component: VatReport,
    meta: { middleware: [auth], title: "VatReportModule",
    //permission: "admin.permissions.show"
   }
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: Permissions,
    meta: { middleware: [auth], title: "PermissionsModule",
    //permission: "admin.permissions.show"
   }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "LoginModule" }
  },
  {
    path: "*",
    name: "Error",
    redirect: { name: "Login" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      to,
      store
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});
export default router;
