import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    api_url: "https://amga-apis.saudi-svs.com/api/portal",
    //api_url: "http://localhost:8000/api/portal",
    App_Name: "easyorder",
    debug: false,
    error_path: "login",
    title_suffix: " easyorder",
    user: null,
    access_token: null,
    permissions: []
  },
  getters: {
    Config(state) {
      return state;
    }
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setAccessToken(state, data) {
      state.access_token = data;
    },
    setPermissions(state, data) {
      state.permissions = data;
    }
  },
  actions: {},
  modules: {},
  plugins: [
    new VuexPersistence({
      reducer: state => ({
        user: state.user,
        access_token: state.access_token,
        permissions: state.permissions
      })
    }).plugin
  ]
});
