<template>
  <section class="content">
     <div class="container-fluid">
      <div class="row">
        <div class="col-12" style="padding-top: 20px;">
            <b-card-text>
            <b-card no-body class="card">
              <b-card-header>
                <div class="row">
                  <div class="col-md-3">
                            <label>{{ $t("VatReportModule.date_from") }}</label>
                            <datepicker v-model="filters.from" :format="customFormatter" name="date_from" input-class="form-control col-md-12"></datepicker>
                  </div>
                  <div class="col-md-3">
                            <label>{{ $t("VatReportModule.date_to") }}</label>
                            <datepicker v-model="filters.to" :format="customFormatter" name="date_to" input-class="form-control col-md-12"></datepicker>
                  </div>
                  <div class="col-md-3" style="display: flex;align-items: end;">
                          <button
                      class="btn btn-primary"
                      style="padding: 5px 25px;margin: 20px 5px 0px 5px;"
                      @click.prevent="SearchFn"
                      :disabled="disabled"
                    >
                      <span v-if="disabled">
                        <i
                          class="fa fa-spinner fa-spin"
                          style="margin: 0 3px;"
                        ></i>
                        {{ $t("loading") }}
                        &nbsp; &nbsp; &nbsp;
                        {{ progress }}%
                      </span>
                      <span v-else>{{ $t("search") }}</span>
                    </button> 
                          <button
                      class="btn btn-success"
                      style="padding: 5px 25px;margin: 20px 5px 0px 5px;"
                      @click.prevent="download"
                      :disabled="download_disabled"
                    >
                      <span v-if="download_disabled">
                        <i
                          class="fa fa-spinner fa-spin"
                          style="margin: 0 3px;"
                        ></i>
                        {{ $t("loading") }}
                        &nbsp; &nbsp; &nbsp;
                        {{ download_progress }}%
                      </span>
                      <span v-else>{{ $t("download") }}</span>
                    </button> 
                    </div>
                   
                </div>
              </b-card-header>
              <!-- /.card-header -->
              <b-card-body class="table-responsive p-0">
                <div class="table-responsive">
                  <table class="table table-hover table-custom spacing8">
                    <thead>
                      <tr>
                        <th>{{ $t("VatReportModule.id") }}</th>
                        <th>{{ $t("VatReportModule.date") }}</th>
                        <th>{{ $t("VatReportModule.sales_total") }}</th>
                        <th>{{ $t("VatReportModule.vat_total") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(report, i) in VatReport" :key="i">
                        <td v-text="i+1"></td>
                        <td v-text="report.date"></td>
                        <td v-text="report.sales_total"></td>
                        <td v-text="report.vat_total"></td>
                      </tr>
                    </tbody>
                  </table>
                  
                </div>
              </b-card-body>
              <!-- /.card-body -->
            </b-card> </b-card-text
        >
        </div>
      </div>
    </div>
   </section>
</template>
<script>
 import Datepicker from 'vuejs-datepicker';
export default {
  name: "ItemsModule",
  components: {
    Datepicker
  },
  data: function() {
    return {
      load: false,
      search_disabled: false,
      progress: 0,
      download_progress: 0,
      VatReport: [],
      disabled: false,
      download_disabled: false,
      filters: {from : '',to: ''},
    };
  },
  created() {
  },
   watch:{
  },
  methods: {
    download(){
        this.download_disabled = true;
        this.filters['download'] = true;  
        this.axios
        .post("/reports/vat_report",this.filters,{
          onUploadProgress: progressEvent => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.download_progress = percentCompleted;
          },
          responseType: 'blob'
        })
        .then(response => {
            let res = response.data;
            const url = window.URL.createObjectURL(new Blob([res],{ type: 'application/pdf;base64' }));
            var newWin = window.open(url);
            newWin.focus();
            newWin.reload();
        })
        .catch(error => {
          if (error) {
            this.handleError("download_invoice", error);
          }
        })
        .finally(() => {
          this.download_progress = 0;
          this.load = true;
          this.download_disabled = false;
        });
    },
    customFormatter(date){
      return this.$moment(date).format('MMMM  YYYY');
    },
    SearchFn() {
      delete this.filters.download;
       this.disabled = true;
       this.axios
        .post("/reports/vat_report", this.filters,{
          onUploadProgress: progressEvent => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.progress = percentCompleted;
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.VatReport = res.result;
          }else {
            this.handleError("SearchFn", res.message, 1);
          }
        })
        .catch(error => {
          if (error) {
            this.handleError("SearchFn", error);
          }
        })
        .finally(() => {
          this.progress = 0;
          this.load = true;
          this.disabled = false;
        });
    }
  }
};
</script>
<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->
<style scoped>
.multiselect__tags {
  border: 1px solid #e2e2e2 !important;
  font-size: 14px !important;
  font-weight: 100 !important;
  min-height: 38px !important;
  padding: 0 10px !important;
}
.multiselect__option {
  font-size: 14px !important;
}
.multiselect__tag {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}
.multiselect__tag span {
  color: white !important;
}
.multiselect__placeholder {
  font-size: 14px !important;
  color: #495057;
  margin-bottom: 0 !important;
  padding-top: 7px;
}
.badge {
  margin-left: 3px !important;
}
.order-table {
  color: #77797c;
}
.order-table td {
  padding: 3px;
}
.order-table td:first-child {
  font-weight: bold;
}
.order-table td:nth-child(2) {
  text-align: center;
}
.order-table tr {
  border-bottom: 1px solid #eee;
}
.products-title {
  margin: 0 auto;
  margin-top: 15px;
  padding: 5px 25px;
  background: #17c2d7;
  font-weight: bold;
  border-radius: 5px;
  color: white;
  width: max-content;
}
</style>
